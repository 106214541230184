
import {computed, defineComponent, ref} from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import router from "@/router";
import Apiservice from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "verify-email",
  components: {},
  created: function () {
    this.startVerify();
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const parseParams = (querystring) => {
      // parse query string
      const params = new URLSearchParams(querystring);
      const obj = [];
      params.forEach((v, k) => {
        obj[k] = v;
      });

     
      return obj;
    };

    //const store = useStore();
    
    const startVerify = () => {
          const data = parseParams(window.location.search);
            
          const url =  data['verify_url'];
          
          if(url){
              if(url.indexOf(".hackingclub.com/") != -1 || url.indexOf(".hackingclub.io/") != -1){
                  Apiservice.get(url).then((res) => {
                      if(res.data.success){
                          Swal.fire({
                              title: t(`${currentLanguage.value}.common.success`),
                              text: t(`${currentLanguage.value}.messages.your_email_has_been_successfully_verified_you_can_now_login`),
                              icon: "success",
                              showCancelButton: false,
                              confirmButtonColor: "#3085d6",
                              confirmButtonText: "OK",
                              allowOutsideClick: false
                          }).then((result) => {
                           
                                  router.push("/sign-in");
                            
                          });
                      }else{
                           Swal.fire({
                                title: "Oops!",
                                text: t(`${currentLanguage.value}.messages.we_had_problem_starting_verify_your_email_browser`),
                                icon: "error",
                                confirmButtonText: "Ok",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                            }).then((result) => {
                                router.push("/sign-in");
                            });
                      }
                  }).catch((err) => {
                       Swal.fire({
                                title: "Oops!",
                                text:  t(`${currentLanguage.value}.messages.we_had_problem_starting_verify_your_email_browser`),
                                icon: "error",
                                confirmButtonText: "Ok",
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                            }).then((result) => {
                                router.push("/sign-in");
                            });
                  });
              }else{
                  console.log("pare te tentar hackear a validação do email!!")
                  
              }
              
          }else{
              Swal.fire({
                title: "Oops!",
                text: t(`${currentLanguage.value}.messages.we_had_problem_starting_verify_your_email_browser`),
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
              }).then((result) => {
                router.push("/sign-in");
              });
          }
          
    };
    
    return {
      startVerify,
      currentLanguage
    };
  },
});
